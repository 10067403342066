import React, { Component } from 'react';
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper";
import './CreditCardForm.css';

export default class CreditCardForm extends Component {
    constructor(props) {
        super(props);
        this.cardRef = React.createRef();

        this.state = {
            token: "",
            error: "",
            loading: false,
            firstName: "",
            options: {
                // Custom classes - applied on container elements based on field's state
                classes: {
                    focus: 'focus',
                    invalid: 'invalid',
                    empty: 'empty',
                    complete: 'complete',
                },

                style: {
                    // Styles for default field state
                    base: {
                        color: '#fff',
                        fontWeight: '500',
                        fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
                        fontSize: '30px',
                        fontSmoothing: 'antialiased',

                        ':focus': {
                            color: '#fff',
                        },

                        '::placeholder': {
                            color: 'transparent',
                        },

                        ':focus::placeholder': {
                            color: '#7b808c',
                        },
                    },

                    // Styles for invalid field state
                    invalid: {
                        color: '#e41029',

                        ':focus': {
                            color: '#e44d5f',
                        },
                        '::placeholder': {
                            color: '#FFCCA5',
                        },
                    },
                },

                // locale
                locale: 'en',

                // Custom placeholders
                placeholder: {
                    number: "4111 1111 1111 1111",
                    expiry: "MM / YY",
                    cvv: "CVV"
                },

                // Custom fonts
                fonts: [
                    'https://fonts.googleapis.com/css?family=Roboto:300,500,600'
                ]
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.tokenize = this.tokenize.bind(this);
        this.seriouslyTokenize = this.seriouslyTokenize.bind(this);
    }

    seriouslyTokenize = () => {
        // Call tokenize methods through  card component's ref
        this.cardRef.current.tokenize({}).then((data) => {
            const token = data.token;
            console.log(`token received: ${token}`);
            this.postTokenToApi(token);
            this.setState({ loading: false, token: data.token });
        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false, token: "", error: "Problem verifying your card details" });
        });
    };

    tokenize = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true, error: "" });
        setTimeout(this.seriouslyTokenize, 50);
    };

    postTokenToApi = (token) => {
        const data = {
            chargebeeToken: token,
            deviceId: this.props.deviceId,
        };
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(data),
        };
        // API base MUST have a trailing slash. This should be the case anyway, given that we are loading this
        // via config, and the configs all have a trailing slash.
        const endpoint = `${this.props.apiBase}pending_billings/${this.props.deviceId}`;
        fetch(endpoint, options)
            .then(r => r.json())
            .then(response => {
                if (response.message !== "success") {
                    this.setState({ loading: false, error: response.message });
                // } else {
                //     alert("ALL SET");
                //     window.close();
                }
            }).catch(error => {
                console.log(error);
                this.setState({ loading: false, error: 'Problem saving your card details' });
            })
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    render() {
        const { style, classes, locale, placeholder, fonts } = this.state.options;
        return (
            <div className="erg container">
                <div className="erg-wrap">
                    <CardComponent ref={this.cardRef}
                                   className="fieldset field"
                                   styles={style}
                                   classes={classes}
                                   locale={locale}
                                   placeholder={placeholder}
                                   fonts={fonts}
                    >
                        <div className="erg-fields">
                            <div className="erg-field" style={{flexBasis: "35%"}}>
                                <input name="firstName"
                                       autoFocus={true}
                                       className={this.state.firstName ? "erg-input val" : "erg-input"}
                                       type="text"
                                       placeholder="Joe Row"
                                       value={this.state.firstName}
                                       onChange={this.handleChange}
                                />
                                <label className="erg-label">Name on Card</label><i className="erg-bar"/>
                            </div>
                        {/* Pass all options as props to card component  */}
                        {/* Assign ref to call internal methods */}

                            <div className="erg-field" style={{flexBasis: "35%"}}>
                                {/* Card number component */}
                                <CardNumber className="erg-input"/>
                                <label className="erg-label">Card Number</label><i className="erg-bar"/>
                            </div>
                            <div className="erg-field" style={{flexBasis: "15%"}}>
                                {/* Card expiry component */}
                                <CardExpiry className="erg-input"/>
                                <label className="erg-label">Expiry</label><i className="erg-bar"/>
                            </div>

                            <div className="erg-field" style={{flexBasis: "15%"}}>
                                {/* Card cvv component */}
                                <CardCVV className="erg-input"/>
                                <label className="erg-label">CVV</label><i className="erg-bar"/>
                            </div>
                        </div>
                    </CardComponent>
                    <button type="submit"
                            className={ this.state.loading ? "submit erg-button" : "erg-button"}
                            onClick={this.tokenize}>SUBSCRIBE</button>
                    {this.state.error && <div className="error" role="alert">{this.state.error}</div>}
                </div>
            </div>
        );
    }
}
